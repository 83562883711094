.off-canvas {
    background:$colorPurple;
    
    .logo {
        color:#fff;
        width:100%;
        text-align:left;
        padding: 30px;
        font-size: 30px;
        font-weight: 600;
        line-height:1.1;
    }
    .menu { 
        li {
            a {
                color: #fff;
                font-weight: 500;
                padding: 15px 30px;
                font-size: 14px;
                transition: ease-in-out .2s;
                &.disabled {
                    pointer-events: none;
                    opacity:.4;
                    cursor:disabled;
                }
                &:hover, &.active {
                    background: #211563;
                }
            }
        }
    }

    .tile {
        padding: 20px;
        background: #fff;
        margin-top: 20px;
        box-shadow: 0px 0px 5px rgba(0,0,0,.1);
    }
}