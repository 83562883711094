body {
    background:$bodyBg;
}

body,p {
    font-size: $bodyFontSize;
    color: $colorTextGrey;
}

body,p,a,li {
    font-family: $mainFont;
}

h1,h2,h3,h4,h5 {
    font-family: $headingFont;
    color: $colorBlack;
}

.grid-container {
    max-width: $containerMaxWidth;
    @media(max-width:1023px){
        max-width:720px;
    }
    @media(max-width:767px){
        max-width:540px;
    }

    @media(max-width:600px){
        max-width:90%;
    }

    @media(max-width:400px){
        max-width:98%;
    }
    
    &.contained {
        max-width: 720px;
    }
}

.main > section:nth-child(even){
    .section-overlay {
        background:#f1f1f1;
    }
}

label {
    color:#222;
    font-weight:500;
}
a,a:focus {
    color: $colorPurple;
    &:hover {
        color:$colorPurple;
        opacity:.7;
    }
}
.button,.button:focus {
    background: $accentColor;
    font-size:12px;
    border-radius:20px;
    padding:10px 20px;
    font-weight:600;
    margin-bottom:0;
    transition: ease-in-out .4s;
    font-size:14px;
    min-width:120px;
    letter-spacing:1px;
    text-transform:uppercase;
    &:hover {
        opacity:.7;
        background:$accentColor;
    }
    
}

* {
    outline: none !important;
}

.reveal.wide {
    max-width:1000px;
    width:80%;
    padding:60px;
    label {
        font-weight:600;
    }
    textarea {
        min-height:100px;
    }

    input, textarea {
        font-size: 14px;
    }
}

.quick-nav {
    display:none !important; // only remove for testing
    position:fixed;
    top:50%;
    left:0;
    box-shadow: $shadow;
    padding:40px;
    background: $colorBlack;
    color:#fff;
    font-weight:bold;
    .nav-title {
        border-bottom:0px solid #fff;
        margin-bottom:15px;
        padding-bottom:5px;
    }
    a {
        display:block;
        color:#fff;
        margin-bottom:10px;
        &.active {
            color: $accentColor;
        }
    }
}

.test-mode-alert {
    background: #f2f4f6;
    padding:5px;
    font-weight:500;
    text-align:center;
    width:100%;
    font-size:12px;
    color: #e74c3c;
}

.error-message {
    position:fixed;
    top:0;
    right:0;
    background: $colorGreen;
    color:#fff;
    padding:20px;
    font-size:14px;
    width:300px;
    display:none !important;

    .error-item {
        margin-bottom:5px;
        font-size:12px;
        border-top:1px dotted #222;
        padding:5px 0px;
    }
    .error-heading {
        font-size:18px;
        font-weight:bold;
        padding-bottom:5px;
        margin-bottom:10px;
    }
    &.warning {
        background: #e9c83e;
        color: #222;;
    }
    &.errors {
        background: #e74c3c;
    }
}